<template>
  <div class="otp-enter-phone-wrapper">
    <div class="icon-wrapper">
      <phone-icon />
    </div>

    <div v-if="!firstStep" class="text-header-1 mb-8px">Add phone number</div>
    <div v-else class="text-header-1 mb-8px">To begin booking, enter your phone number</div>
    <div v-if="!firstStep" class="text-body-4 mb-32px">
      We'll use this to verify it's you and send reminders about your appointment.
    </div>
    <div v-else class="text-body-4 mb-32px">
      We'll use this to verify it's you and send reminders about your appointment.
    </div>

    <el-input
      placeholder="Enter phone number"
      type="tel"
      v-model="phone"
      autocomplete="tel"
      inputmode="tel"
      oninput="this.value = this.value.replace(/[^\d+]+/g, '').replace(/(\..*)\./g, '$1')"
    />

    <transition name="fade-faster">
      <div class="mt-12px" v-if="error">
        <el-alert class="text-left" effect="dark" :title="error" type="error" />
      </div>
    </transition>

    <el-checkbox class="terms-checkbox mt-16px" v-model="termsAccepted">
      <div v-if="!firstStep" class="text-body-5 text-left">
        I agree to receive SMS & email marketing messages from
        {{ accountName }}. Msg & data rates may apply for SMS. Message frequency may vary. Reply STOP to opt-out. Text
        HELP for help.
        <router-link :to="'/terms?shop=' + this.accountSlug" target="_blank"> Terms & Conditions </router-link>
        |
        <router-link :to="'/privacy?shop=' + this.accountSlug" target="_blank"> Privacy Policy </router-link>
      </div>
      <div v-else class="text-body-5 text-left">
        I agree to receive SMS and email messages from {{ accountName }}, which may include marketing. Msg & data rates
        may apply for SMS. Message frequency may vary. Reply STOP to opt-out. Text HELP for help.
        <router-link :to="'/terms?shop=' + this.accountSlug" target="_blank"> Terms & Conditions </router-link>
        |
        <router-link :to="'/privacy?shop=' + this.accountSlug" target="_blank"> Privacy Policy </router-link>
      </div>
    </el-checkbox>

    <transition name="fade-faster">
      <sticked-to-bottom-button
        v-if="phone && phone.length >= 4 && termsAccepted"
        :loading="loading"
        @btn-click="sendOTP"
      />
    </transition>
  </div>
</template>

<script>
import PhoneIcon from './PhoneIcon';
import StickedToBottomButton from '@/components/global/StickedToBottomButton';

import OTPService from '@/services/OTPService';

export default {
  name: 'EnterCustomerPhone',
  components: {
    PhoneIcon,
    StickedToBottomButton,
  },
  props: {
    accountSlug: {
      type: String,
      required: true,
    },
    accountName: {
      type: String,
      required: true,
    },
    accountOtpSetting: {
      type: String,
      required: true,
    },
    firstStep: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      phone: '',
      email: '',
      loading: false,
      error: '',
      termsAccepted: false,
    };
  },
  methods: {
    async sendOTP() {
      if (this.error) this.error = '';

      try {
        this.loading = true;
        let data, response;
        if (this.accountOtpSetting === 'sms') {
          response = await OTPService.post.sendOTP(this.accountSlug, this.phone);
        } else {
          response = await OTPService.post.sendEmailOTP(this.accountSlug, this.phone);
        }
        data = response.data || {};
        this.loading = false;
        this.$posthog.capture('addPhoneNumber_*_otpSent');
        this.$emit('otp-sent', {
          phone: this.phone,
          email: data.email || '',
          otpSent: data.otp_sent,
          termsAccepted: this.termsAccepted,
        });
        // Enabled abandonment timer
        this.$emit('start-abandonment-timer', { phone: this.phone, email: this.email });
      } catch (error) {
        this.loading = false;
        console.log('error', error);
        this.error = error.response.data.message || '';
        this.$posthog.capture('addPhoneNumber_*_otpSentFailed', {
          errorMessage: this.error,
        });
      }
    },
  },
};
</script>

<style></style>

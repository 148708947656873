<template>
  <div class="pitcrew-rewards-wrapper">Book your first appointment to start earning rewards 🎉</div>
</template>

<script>
export default {
  name: 'PitCrewNewCustomer',
};
</script>

<style scoped>
.pitcrew-rewards-wrapper {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  color: #036945;
  background-color: #b9f9e2;
  padding: 8px;
  text-align: center;
  margin-left: -12px;
  margin-right: -12px;
}
</style>
